export const tableColumns = [
	{
		model: 'OtypeId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'OtypeNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OtypeNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OtypeNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OtypeNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OtypeNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OtypeNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
];

<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="objecttypes3523"
		tooltipMsg="manualobjecttyp"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OtypeId', ascending: 0 }"
		modelId="OtypeId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	></table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './object-type.table-data';

export default {
	name: 'ObjectTypeTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectType',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.objectTypesDetailName,
				params: { id },
			});
		},
	},
};
</script>